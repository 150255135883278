body {
  padding: 0;
  margin: 0;
  font-weight: 700;
}

.dragged_item {
  z-index: 999;
  font-family: -apple-system, system-ui, Helvetica Neue, Roboto, sans-serif;
}

// vkui fixes
.vkuiFormField__after {
  cursor: pointer;
}

// ==== HIDE NUMBER INPUT ARROWS ====
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.vkuiPlaceholder__header {
  font-family: VKSansDisplayBold, sans-serif;
  font-weight: 600;
}
